import { gql } from "../../apis/__generated__";

export const GET_CANCEL_LOCKUP_PROPOSALS = gql(/* GraphQL */ `
  query cancelLockupProposals(
    $first: Int
    $orderBy: CancelLockupProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: CancelLockupProposal_filter
  ) {
    cancelLockupProposals(
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      owner
      index
      administratorApproved
      keyKeeperApproved
      tokenOwnerApproved
      status
    }
  }
`);

export const GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS = gql(/* GraphQL */ `
  query transferTokenOwnershipProposals(
    $first: Int
    $orderBy: TransferTokenOwnershipProposal_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: TransferTokenOwnershipProposal_filter
  ) {
    transferTokenOwnershipProposals(
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      owner
      index
      newOwner
      status
    }
  }
`);

import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneIcon from "@mui/icons-material/Done";
import {
  OrderDirection,
  TransferTokenOwnershipProposal,
  TransferTokenOwnershipProposal_OrderBy,
} from "../../../../apis/__generated__/graphql";
import theme from "../../../../theme";
import ProposalStatusChip from "../../../shared/ProposalStatusChip";
import RevokeProposalButton from "../buttons/RevokeProposalButton";
import { useCallback, useState } from "react";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { useAccount } from "@gusdk/gu-wallet-connector";
import { useQuery } from "@apollo/client";
import { ProposalStatus } from "../../../../libraries/types/proposal";
import { GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS } from "../../../../libraries/queries/proposals";

export interface TransferTokenOwnershipProposalTableProps {}

export default function TransferTokenOwnershipProposalTable() {
  const [selectedRow, setSelectedRow] = useState<
    TransferTokenOwnershipProposal | undefined
  >(undefined);

  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const { account } = useAccount();

  const {
    data: proposalData,
    refetch,
    loading,
  } = useQuery(GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS, {
    variables: {
      first: paginationModel.pageSize,
      skip: paginationModel.pageSize * paginationModel.page,
      orderDirection:
        sortModel?.[0]?.sort === "asc"
          ? OrderDirection.Asc
          : OrderDirection.Desc,
      orderBy: sortModel?.[0]?.field as TransferTokenOwnershipProposal_OrderBy,

      where: {
        owner: account.toLowerCase(),
        status: ProposalStatus.Pending,
      },
    },
  });

  const proposals = (proposalData?.transferTokenOwnershipProposals ||
    []) as TransferTokenOwnershipProposal[];

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps["onPaginationModelChange"]>
  >((model) => {
    setPaginationModel(model);
  }, []);

  const onSortModelChange = useCallback<
    NonNullable<DataGridProProps["onSortModelChange"]>
  >(
    (model) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "Index",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      minWidth: 70,
      width: 70,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.3,
      minWidth: 90,
      valueGetter: ({ value }) => {
        return isLargeScreen ? "You" : value;
      },
    },
    {
      field: "newOwner",
      headerName: "New Owner",
      flex: 0.3,
      minWidth: 130,
      valueGetter: ({ value }) => {
        return isSmallScreen
          ? value.slice(0, 5) + "…" + value.slice(-5)
          : value;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "confirmations",
      headerName: "Confirmations",
      width: 140,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography fontSize={14} display="flex">
              Administrator:{" "}
              {row.administratorApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 60,
      getActions: ({ row }) => {
        return [
          <RevokeProposalButton
            key="Revoke"
            disabled={false}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
        ];
      },
    },
  ];

  return (
    <Paper>
      <DataGridPro
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
        }}
        pagination
        rows={proposals}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={
          proposals.length === 10
            ? paginationModel.pageSize * (paginationModel.page + 1) + 1
            : paginationModel.pageSize * (paginationModel.page + 1)
        }
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        rowHeight={100}
        paginationModel={paginationModel}
        sortModel={sortModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
      />
    </Paper>
  );
}

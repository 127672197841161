import Paper from "@mui/material/Paper";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneIcon from "@mui/icons-material/Done";
import theme from "../../../../theme";
import ProposalStatusChip from "../../../shared/ProposalStatusChip";
import { useCallback } from "react";
import { useState } from "react";
import { useAccount } from "@gusdk/gu-wallet-connector";
import { useQuery } from "@apollo/client";
import { GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS } from "../../../../libraries/queries/proposals";
import {
  OrderDirection,
  TransferTokenOwnershipProposal,
  TransferTokenOwnershipProposal_OrderBy,
} from "../../../../apis/__generated__/graphql";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { ProposalStatus } from "../../../../libraries/types/proposal";

export interface ProceedTransferTokenOwnershipProposalTableProps {}

export default function ProceedTransferTokenOwnershipProposalTable() {
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const { account } = useAccount();

  const { data: proposalData, loading } = useQuery(
    GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS,
    {
      variables: {
        first: paginationModel.pageSize,
        skip: paginationModel.pageSize * paginationModel.page,
        orderDirection:
          sortModel?.[0]?.sort === "asc"
            ? OrderDirection.Asc
            : OrderDirection.Desc,
        orderBy: sortModel?.[0]
          ?.field as TransferTokenOwnershipProposal_OrderBy,

        where: {
          owner: account.toLowerCase(),
          status_not: ProposalStatus.Pending,
        },
      },
    }
  );

  const proposals = (proposalData?.transferTokenOwnershipProposals ||
    []) as TransferTokenOwnershipProposal[];

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps["onPaginationModelChange"]>
  >((model) => {
    setPaginationModel(model);
  }, []);

  const onSortModelChange = useCallback<
    NonNullable<DataGridProProps["onSortModelChange"]>
  >(
    (model) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "Index",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      minWidth: 90,
      width: 90,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.3,
      minWidth: 90,
      valueGetter: ({ value }) => {
        return isLargeScreen ? "You" : value;
      },
    },
    {
      field: "newOwner",
      headerName: "New Owner",
      flex: 0.3,
      minWidth: 130,
      valueGetter: ({ value }) => {
        return isSmallScreen
          ? value.slice(0, 5) + "…" + value.slice(-5)
          : value;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "confirmations",
      headerName: "Confirmations",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography fontSize={14} display="flex">
              Administrator:{" "}
              {row.administratorApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper>
      <DataGridPro
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
        }}
        pagination
        rows={proposals}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={
          proposals.length === 10
            ? paginationModel.pageSize * (paginationModel.page + 1) + 1
            : paginationModel.pageSize * (paginationModel.page + 1)
        }
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        rowHeight={100}
        paginationModel={paginationModel}
        sortModel={sortModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
      />
    </Paper>
  );
}

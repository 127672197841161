import Box from "@mui/material/Box";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import TransferTokenOwnershipProposalTable from "../components/proposals/transfer-token-ownership/tables/TransferTokenOwnershipProposalTable";
import AddButton from "../components/proposals/transfer-token-ownership/buttons/AddButton";
import theme from "../theme";
import ProposalTabs from "../components/shared/ProposalTabs";
import ProceedTransferTokenOwnershipProposalTable from "../components/proposals/transfer-token-ownership/tables/ProceedTransferTokenOwnershipProposalTable";

export default function TransferVestingProposalPage() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant={isSmallScreen ? "h6" : "h5"} sx={{ flexGrow: 1 }}>
          Transfer Token Ownership Proposal
        </Typography>
        <AddButton />
      </Stack>
      <ProposalTabs
        processingTab={<TransferTokenOwnershipProposalTable />}
        proceedTab={<ProceedTransferTokenOwnershipProposalTable />}
      />
    </Box>
  );
}

import {} from "ethers";
import { useCallback, useState } from "react";
import ConfirmButton from "../buttons/ConfirmButton";
import { Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneIcon from "@mui/icons-material/Done";
import {
  CancelLockupProposal,
  OrderDirection,
  CancelLockupProposal_OrderBy,
} from "../../../../apis/__generated__/graphql";
import RevokeProposalButton from "../buttons/RevokeProposalButton";
import theme from "../../../../theme";
import ProposalStatusChip from "../../../shared/ProposalStatusChip";
import { useAccount } from "@gusdk/gu-wallet-connector";
import { GET_CANCEL_LOCKUP_PROPOSALS } from "../../../../libraries/queries/proposals";
import { ProposalStatus } from "../../../../libraries/types/proposal";
import { useQuery } from "@apollo/client";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSortModel,
} from "@mui/x-data-grid-pro";

export interface CancelLockupProposalTableProps {}

export default function CancelLockupProposalTable() {
  const [selectedRow, setSelectedRow] = useState<
    CancelLockupProposal | undefined
  >(undefined);
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const { account } = useAccount();

  const {
    data: proposalData,
    refetch,
    loading,
  } = useQuery(GET_CANCEL_LOCKUP_PROPOSALS, {
    variables: {
      first: paginationModel.pageSize,
      skip: paginationModel.pageSize * paginationModel.page,
      orderDirection:
        sortModel?.[0]?.sort === "asc"
          ? OrderDirection.Asc
          : OrderDirection.Desc,
      orderBy: sortModel?.[0]?.field as CancelLockupProposal_OrderBy,

      where: {
        owner: account.toLowerCase(),
        status: ProposalStatus.Pending,
      },
    },
  });

  const proposals = (proposalData?.cancelLockupProposals ||
    []) as CancelLockupProposal[];

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps["onPaginationModelChange"]>
  >((model) => {
    setPaginationModel(model);
  }, []);

  const onSortModelChange = useCallback<
    NonNullable<DataGridProProps["onSortModelChange"]>
  >(
    (model) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const columns: GridColDef<CancelLockupProposal>[] = [
    {
      field: "index",
      headerName: "Index",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      minWidth: 90,
      width: 90,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.4,
      minWidth: 90,
      valueGetter: ({ value }) => {
        return isLargeScreen ? "You" : value;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "confirmations",
      headerName: "Confirmations",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography fontSize={14} display="flex">
              Administrator:{" "}
              {row.administratorApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
            <Typography fontSize={14} display="flex">
              Key Keeper:{" "}
              {row.keyKeeperApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
            <Typography fontSize={14} display="flex">
              Token Owner:{" "}
              {row.tokenOwnerApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
          </Stack>
        );
      },
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      width: 150,
      getActions: ({ row }) => [
        <ConfirmButton
          key="Confirm"
          disabled={row.tokenOwnerApproved}
          onSelectedRow={() => setSelectedRow(row)}
          onUnselectedRow={() => setSelectedRow(undefined)}
          row={selectedRow}
          refetch={refetch}
        />,
        <RevokeProposalButton
          key="Revoke"
          disabled={false}
          onSelectedRow={() => setSelectedRow(row)}
          onUnselectedRow={() => setSelectedRow(undefined)}
          row={selectedRow}
          refetch={refetch}
        />,
      ],
    },
  ];

  return (
    <Paper>
      <DataGridPro
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
        }}
        pagination
        rows={proposals}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={
          proposals.length === 10
            ? paginationModel.pageSize * (paginationModel.page + 1) + 1
            : paginationModel.pageSize * (paginationModel.page + 1)
        }
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        rowHeight={100}
        paginationModel={paginationModel}
        sortModel={sortModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
      />
    </Paper>
  );
}
